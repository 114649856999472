.servicosOferecidos {
  max-width: auto;
  margin: auto;
  padding: 20px;
  height: auto;
  width: auto;
  background-color: #D9D9D9;
  cursor: pointer;
  
}



#tituloPrincipal {
  color: #133227;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 40px;
}

#carrossel {
  display: flex;
  overflow: hidden;
  margin: 20px;
  justify-content: space-between;
  width: auto;
}

.retangulo {
  border-radius: 12px;
  border: 1px solid rgba(44, 76, 63, 0.10);
  background: #D9D9D9;
  box-shadow: 4px 2px 4px 1px rgba(0, 0, 0, 0.15);
  width: 100%;
  max-width: 431px;
  height: auto;
  flex-shrink: 0;
  margin-right: 20px;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
}

.retangulo.ativo {
  opacity: 1;
  transform: scale(1.05);
  margin-top: 20px;
  margin-bottom: 20px;
  /* box-shadow: 4px 2px 4px 1px rgba(0, 0, 0, 0.15); */
  box-shadow: 4px 2px 4px 2px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  outline: none;
  
}

.retangulo .titulo {
  color: #133227;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 10px;
  display: flex;
  text-align: left;
  align-items: center;
  cursor: pointer;
}

.retangulo .titulo-texto .space {
  margin-right: 10px; /* Ajuste a margem conforme necessário para o espaço horizontal desejado */
}



.retangulo .titulo .titulo-texto {
  color: #133227;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
  margin-top: 20px;
  padding: 0px 0px 0px 0px;
  align-items: center;
  text-align: left;
  display: flex;
}

.retangulo .texto {
  color: #133227;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 5px 5px 5px;
  text-align: left;
}

#setaEsquerda {
  background-color: rgba(172, 121, 83, 0.25);
  border-radius: 100%;
  width: 44px;
  height: 44px;
}

#setaDireita {
  width: 44px;
  height: 44px;
}
.seta-esquerda-icon {
  margin-top: 10px;
  width: 24px;
  height: 24px;
}
.seta-direita-icon {
  margin-top: 10px;
  width: 24px;
  height: 24px;
}

#setasContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  
}

.texto-cultivamos {
  color: #133227;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 10px;
  margin-right: 10px;
}

@media (min-width: 1200px) {
  .servicosOferecidos {
    max-width: auto;
    margin: auto;
    padding: 20px;
    height: 680px;
    width: auto;
    background-color: #D9D9D9;
    cursor: pointer;
  }
  
  #tituloPrincipal {
    
    font-size: 37px;
    margin-top: 50px; /* Retirar */
    margin-bottom: 40px; /* Retirar */
  }
  
  #carrossel {
    display: flex;
    overflow: hidden;
    margin: 20px 20px 20px 20px;
    justify-content: space-between;
    width: auto;
  }
  
  .retangulo {
    border-radius: 12px;
    border: 1px solid rgba(44, 76, 63, 0.10);
    background: #D9D9D9;
    box-shadow: 4px 2px 4px 1px rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 631px;
    height: auto;
    flex-shrink: 0;
    margin-right: 50px;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    opacity: 0.7;
    cursor: pointer;
  }
  
  .retangulo.ativo {
    opacity: 1;
    height: auto;
    transform: scale(1.05);
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    box-shadow: 4px 2px 4px 2px rgba(0, 0, 0, 0.15);
  }
  

  
  .retangulo .titulo-texto .space {
    margin-right: 10px; /* Ajuste a margem conforme necessário para o espaço horizontal desejado */
  }
  
  
  
  .retangulo .titulo .titulo-texto {
    
    font-size: 27px;

  }
  
  .retangulo .texto {
    color: #133227;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 5px 5px 5px 5px;
    text-align: left;
  }
  
  #setaEsquerda {
    width: 54px;
    height: 54px;
  }
  
  #setaDireita {
    width: 54px;
    height: 54px;
  }
  .seta-esquerda-icon {
    
    width: 34px;
    height: 34px;
  }
  .seta-direita-icon {
    
    width: 34px;
    height: 34px;
  }
  
  
  .texto-cultivamos {
    font-size: 25px;
    margin-left: 40px;
    margin-right: 40px;
    
  }
}

@media (max-width: 768px) {

  .retangulo.ativo {
    opacity: 1;
    transform: scale(1.05);
    margin-top: 40px;
    margin-bottom: 10px;
    margin-left: 0px;
    margin-right: 0px;
    box-shadow: 4px 2px 4px 1px rgba(0, 0, 0, 0.15);
   
  }

  .retangulo .texto {
    padding: 5px 10px 5px 10px;
  
  }

  .retangulo .titulo .titulo-texto {
    font-size: 20px;
    margin-top: 60px;
    margin-bottom: 0px;
  }
  

  

  #tituloPrincipal {
    
    font-size: 25px;
    margin-top: 50px; /* Retirar */
    margin-bottom: 40px; /* Retirar */
  }

  .retangulo .titulo .titulo-texto {
    white-space: nowrap; /* Evita que o texto do título quebre */
    overflow: hidden; /* Evita que o texto vaze */
    text-overflow: ellipsis; /* Mostra reticências (...) se o texto vazar */
    max-width: 100%;
  }

 

 

}