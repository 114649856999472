.hamburger {
    display: none;
}

@media (max-width: 768px) {



.hamburger {
    display: flex;
    cursor: pointer;
    margin-right: 20px;
  }
  
  .hamburger input {
    display: none;
  }
  
  .hamburger svg {
    height: 3em;
    transition: transform 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .line {
    fill: none;
    stroke: #D1CCBE;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
                stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .line-top-bottom {
    stroke-dasharray: 12 63;
  }
  
  .hamburger.open svg {
    transform: rotate(-45deg);
  }
  
  .hamburger.open svg .line-top-bottom {
    stroke-dasharray: 20 300;
    stroke-dashoffset: -32.42;
  }

  .popup2 {
    position: fixed;
    top: 150px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #2C4C3F;
    padding: 0px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 999;
    
    width: 100%;
    text-align: left;
    cursor: pointer;
  }

  .popup2 ul {
    list-style: none;
    padding: 0;
    margin: 100;
    
    
  }

  .popup2 li {
    margin-bottom: 10px;
    color: #EFEDEA;
    font-family: Montserrat;
    font-size: 18px;
    margin-left: 20px;
    margin-right: 20px;
    border-bottom: 1px solid #D1CCBE;
    padding-bottom: 5px;
  }

  

  .popup li:last-child {
    border-bottom: none; /* Remove a borda inferior do último item para evitar linha extra */
  } 

 

}