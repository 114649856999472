.politica-container {
  padding: 20px;
  padding-top: 15px; /* Adicionando o mesmo padding-top de 100px */
  background-color: #2C4C3F; /* Adicionando o mesmo background-color */
  text-align: left; /* Adicionando o mesmo text-align */
  font-family: Arial, sans-serif;
}

.politica-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #D9D9D9; /* Adicionando a mesma cor */
}

.politica-container h2 {
  font-size: 20px;
  margin-top: 20px;
  color: #D9D9D9; /* Adicionando a mesma cor */
}

.politica-container p {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 15px;
  color: #D9D9D9; /* Adicionando a mesma cor */
}
