/* Estilos genéricos para o corpo da página */
.Layout {
    margin: 0;
    padding: 0;
    background-color: #2C4C3F;
    font-family: Montserrat;
    cursor: pointer;
    user-select: none;
    overflow-x: hidden;
    overflow-y: hidden;
    scroll-behavior: smooth;
  }
  
  