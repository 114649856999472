.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  flex-shrink: 0;
  border-radius: 0;
  background: #2C4C3F;
  margin-right: auto;
  z-index: 1;
  cursor: pointer;
  position: fixed;
  font-family: 'Montserrat';
}

.logo img {
  width: 126px;
  height: 46px;
  flex-shrink: 0;
  margin-left: 80px;
}

a {
  text-decoration: none;
  color: inherit;
  outline: none;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
}

.menu ul {
  list-style: none;
  display: flex;
}

.menu li {
  margin-right: 30px;
  color: #FFF;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 300;
  cursor: pointer;
  line-height: normal;
}

@media (min-width: 1200px) {
  .logo img {
    margin-left: 75px;
  }
  .menu li {
    font-size: 20px;
  }
   

}

@media (max-width: 768px) 

{
  .header {
    height: auto;
    width: 100%;
    position: fixed;
  }

  .logo img {
    width: 126px;
    height: 46px;
    flex-shrink: 0;
    margin-left: 25px;
  }


  menu li, .menu ul {
  display: none;
  } 

} 