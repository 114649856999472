/* Contato.css */

.contato {
    display: flex;
    height: auto;
    width: auto;
    background-color: #2C4C3F;
    padding-top: 122px;
    padding-bottom: 187px;
  }
  
  .coluna-esquerda {
    width: auto;
    max-width: 500px;
    color: #D9D9D9;
    font-family: Montserrat;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-align: left;
  }
  
  .titulo {
    color: #D9D9D9;
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    font-family: Montserrat;
    text-align: left;
    margin-left: 70px;
    margin-right: 0px;
  }

  .texto {
    color: #D9D9D9;
    font-size: 20px;
    text-align: left;
    margin-left: 70px;
    margin-right: 0px;
  }

  .textodebaixo {
    color: #D9D9D9;
    font-size: 22px;
    margin-left: 70px;
    margin-right: 0px;
    text-align: left;
  }
  
  .botoes {
    display: flex;
    gap: 50px;
    margin-left: 70px;
    margin-right: 0px;
    
  }
  
  .botoes button {
    color: #133227;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    line-height: normal;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border-color: #D9D9D9;
  }
  
  .botoes button img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
 

  }
  
  .email {
  text-align: left;
  user-select: text;
  }

  .envelope{
  font-size: 20px;
  color: #FFF;
  align-items: left;
  } 

  .coluna-direita {
    margin-left: auto;
    margin-right: 70px;
  }
  
  .coluna-direita img {
    width: 449px;
    height: 573px;
    border-radius: 50px;
  }

  /* Icone do WhatsApp Flutuante */ 
  
    .whatsapp-icon-bottom {
      position: fixed;
      bottom: 20px;
      right: 20px;
    }
    
    .whatsapp-icon {
      font-size: 56px;
      color: #25d366; /* Cor verde do WhatsApp */
    }
 
@media (min-width: 1200px) {
  .coluna-esquerda {
    max-width: 900px;
    font-size: 37px;
    margin-top: 70px;
    margin-right: 30px; /* Afastamento direita */
  
  }
  
  .titulo {
    
    font-size: 37px;
  
  }

  .texto {
    
    font-size: 25px;
    
  }

  .textodebaixo {
    
    font-size: 27px;
   
  }
  

  
  .botoes button {
   
    font-size: 20px;
    

    }
   


  .coluna-direita {
  margin-left: auto;
  margin-right: 70px;
    }

 .coluna-direita img {
  width: 549px;
  height: 673px;

  }



  .whatsapp-icon {
  font-size: 76px;
  
  }
 


  }

  
@media (max-width: 768px) {

    /* Contato.css */

.contato {
  display: flex;
  height: auto;
  width: auto;
  flex-direction: column;
  background-color: #2C4C3F;
  padding-top: 50px;
  padding-bottom: 0px;
}

.coluna-esquerda {
  width: auto;
  max-width: 400px;
  color: #FFF;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-align: left;
  order: 2;
  margin-bottom: 20px;
}

.titulo {
  color: #FFF;
  font-size: 25px;
  font-style: normal;
  font-weight: 300;
  font-family: Montserrat;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: -50px;
}

.texto {
  color: #D9D9D9;
  font-size: 18px;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
}

.textodebaixo {
  color: #D9D9D9;
  font-size: 18px;
  margin-left: 20px;
  margin-right: 10px;
  text-align: left;
  justify-content: center;
  margin-top: 20px;
  
}

.botoes {
  display: flex;
  gap: 10px;
  margin-left: 0px;
  align-items: center;
  justify-content: center;
  

}

.botoes button {
  color: #133227;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  
}

.botoes button img {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}



.email {
text-align: left;
margin-left: 20px;
}

.envelope{
  font-size: 15px;
  color: #D9D9D9;
}

.coluna-direita {
  margin-left: 0px;
  margin-right: 0px;
  order: 1;
}

.coluna-direita img {
  width: 90%;
  height: auto;
  border-radius: 50px;
  
}

.whatsapp-icon {
  font-size: 56px;
  
  }


    }