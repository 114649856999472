/* rodape.css */


#rodapeContainer {
    width: 80%;
    height: 140px;
    flex-shrink: 0;
    border-radius: 35px 35px 0px 0px;
    background: #D9D9D9;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    
  }
  
  .iconContainer {
    display: flex;
    gap: 25px;
    align-items: center;
    margin-top: 25px;
  }
  
  .text1 {
    color: #133227;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.9;
    margin-bottom: 0px;
  }
  
  .text2 {
    color: #133227;
    font-family: Montserrat;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    opacity: 0.9;
  }

@media (min-width: 1200px) {
  #rodapeContainer {
    width: 90%;
    height: 120px;
    
  
  }
  
  .iconContainer {
    gap: 50px;
    margin-top: 10px;
    
    
  }
  
  .text1 {

    font-size: 17px;
    
  }
  
  .text2 {

    font-size: 17px;
 
  }

}
  
@media (max-width: 768px) {


#rodapeContainer {
  width: 90%;
  height: 140px;
  flex-shrink: 0;
  border-radius: 35px 35px 0px 0px;
  background: #D9D9D9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  
}

.iconContainer {
  display: flex;
  gap: 25px;
  align-items: center;
  margin-top: 20px;
}

.text1 {
  color: #133227;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 1;
  margin-bottom: 0px;
}

.text2 {
  color: #133227;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 1;
}


}