/* icones.css */

.svg-container {
    position: relative;
    
  }
  
  .elipse-background {
    background-size: cover;
    width: 32px; /* Novo tamanho do elipse */
    height: 32px; /* Novo tamanho do elipse */
    position: relative;
    
  }
  
  .pen-icon {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50% );
    width: 38px; /* Tamanho do pen */
    height: 38px; /* Tamanho do pen */
  }
  
  .outros-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50% );
    width: 22px; /* Tamanho do pen */
    height: 22px; /* Tamanho do pen */
  }

 