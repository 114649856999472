/* animations.css */

/* Animação para subir os elementos */
@keyframes slideUp {
    from {
      transform: translateY(100%); /* Começa abaixo do elemento */
      opacity: 0; /* Começa invisível */
    }
    to {
      transform: translateY(0); /* Move para a posição original */
      opacity: 1; /* Torna-se visível */
    }
  }
  
  /* Animações para entrada da esquerda e direita */
  @keyframes slideInFromLeft {
    from {
      transform: translateX(-100%); /* Começa fora à esquerda */
      opacity: 0; /* Começa invisível */
    }
    to {
      transform: translateX(0); /* Move para a posição original */
      opacity: 1; /* Torna-se visível */
    }
  }
  
  @keyframes slideInFromRight {
    from {
      transform: translateX(100%); /* Começa fora à direita */
      opacity: 0; /* Começa invisível */
    }
    to {
      transform: translateX(0); /* Move para a posição original */
      opacity: 1; /* Torna-se visível */
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
  
  
  
  /* Aplicação das animações para diferentes elementos */
  
  /* Fachada */
  .main-text, .sub-text, .cta-button {
    animation: slideUp 0.9s ease-in-out; /* Aplica a animação de subir */
  }
  
  .icone-seta {
    animation: pulse 1s infinite;
  }
  
  /* QuemSomos */
  .quem-somos-title, .quem-somos-text, .quem-somos-image-container {
    animation: slideUp 0.9s ease-in-out; /* Aplica a animação de subir */
  }
  
  /* Porque */
  .tituloabc, .textoabc, .titulo-especial {
    animation: slideUp 0.9s ease-in-out; /* Aplica a animação de subir */
  }
  
  /* Classes específicas com animações horizontais */
  .lado-esquerdo {
    animation: slideInFromLeft 0.9s ease-in-out; /* Aplica a animação de entrada da esquerda */
  }
  
  .lado-direito {
    animation: slideInFromRight 0.9s ease-in-out; /* Aplica a animação de entrada da direita */
  }
  
  .frase-final {
    animation: slideUp 0.9s ease-in-out; /* Aplica a animação de subir */
  }

  /* Como funciona */

  .titulo-comofunciona {
    animation: slideUp 0.9s ease-in-out; /* Aplica a animação de subir */
  }

  .descricao-comofunciona {
    animation: slideUp 0.9s ease-in-out; /* Aplica a animação de subir */
  }

  .imagem-container {
    animation: slideInFromLeft 0.9s ease-in-out;
  }
