/* Comofunciona.css */

.comofunciona-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  background: #2C4C3F;
  margin-top: 0px;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.imagem-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagem-container img {
  margin: 50px 20px;
  border-radius: 50px;
  width: 75%;
  height: auto;
}

.conteudo-container {
  flex: 1;
  padding: 18px;
  color: #D9D9D9;
  font-family: 'Montserrat', sans-serif;
}

.titulo-comofunciona {
  font-size: 25px;
  font-weight: 300;
  line-height: 1.3;
  margin-bottom: 20px;
  text-align: left;
  color: #D9D9D9;
}

.descricao-comofunciona {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
  margin: 10px 0;
  text-align: left;
  color: #D9D9D9;
  hyphens: auto;
}

/* Estilos Responsivos */
@media (min-width: 1200px) {
  .titulo-comofunciona {
    font-size: 30px;
    margin-right: 30px;
    margin-top: 50px;
  }

  .descricao-comofunciona {
    font-size: 20px;
    margin-top: 40px;
    margin-right: 30px;
  }

  .imagem-container img {
    margin: 50px 20px 0px 20px;
  }
}

@media (max-width: 768px) {

  .comofunciona-container {
    flex-direction: column;
    margin-top: 0;
  }
  
  .imagem-container img {
    margin: 10px 0;
    border-radius: 50px;
    width: 85%;
    height: auto;
  }
  
  .conteudo-container {
    margin-top: -80px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 0;
    text-align: center;
  }
  
  .titulo-comofunciona {
    font-size: 25px;
    margin: 10px;
    overflow: hidden;
  }
  
  .descricao-comofunciona {
    font-size: 18px;
    margin: 0 10px 5px 10px;
    overflow: hidden;
  }

  .descricao-comofunciona p {
    
    align-items: left;

  }
}
