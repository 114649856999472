/* Estilos globais para as seções */
.quem-somos-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background: #2C4C3F;
  padding: 20px; /* Padding em pixels para consistência */
}

/* Estilos para a seção da esquerda e da direita */
.quem-somos-left,
.quem-somos-right {
  flex: 1;
  padding: 15px; /* Padding em pixels */
  box-sizing: border-box;
}

.quem-somos-title {
  color: #D9D9D9;
  font-family: "Montserrat", sans-serif;
  font-size: 32px; /* Tamanho da fonte em pixels */
  font-weight: 400;
  text-align: left;
  margin-bottom: 5px; /* Espaçamento inferior reduzido */
}

.somos-highlight {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 700;
}

.quem-somos-text {
  color: #D9D9D9;
  font-family: Montserrat;
  font-size: 16px; /* Tamanho da fonte em pixels */
  font-weight: 400;
  text-align: left;
  max-width: 500px; /* Largura máxima em pixels */
  margin-bottom: 10px; /* Espaçamento inferior um pouco maior */
}

.quem-somos-image-container {
  width: 480px;
  max-width: 100%;
  border-radius: 28px; /* Borda arredondada em pixels */
  overflow: hidden;
  background: #D9D9D9;
}

.quem-somos-image {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 28px; /* Borda arredondada em pixels */
}

.mas-text {
  color: #D9D9D9;
  font-family: Montserrat;
  font-size: 20px; /* Tamanho da fonte em pixels */
  font-weight: 700;
  text-align: left;
  max-width: 500px; /* Largura máxima em pixels */
  margin-bottom: 10px; /* Espaçamento inferior um pouco maior */
}

/* Media queries para tamanhos de tela */

/* Tablets (768px a 1024px) */
@media (min-width: 768px) and (max-width: 1024px) {


  .quem-somos-title {
    font-size: 20px;
    margin-top: -150px; /* Ajuste específico para tablets */
  }

  .quem-somos-text {
    font-size: 12px;
    max-width: 600px;
    text-align: justify;
    hyphens: auto;
  }

  .mas-text {
    color: #D9D9D9;
    font-family: Montserrat;
    font-size: 15px; /* Tamanho da fonte em pixels */
    font-weight: 700;
    text-align: left;
    max-width: 600px; /* Largura máxima em pixels */
    margin-bottom: 10px; /* Espaçamento inferior um pouco maior */
  }

  .quem-somos-left,
  .quem-somos-right {
    padding: 20px; /* Ajuste de padding para tablets */
  }

  
  .quem-somos-image-container {
    width: 300px;
    max-width: 100%;
    border-radius: 28px; /* Borda arredondada em pixels */
    overflow: hidden;
    background: #D9D9D9;
  }
  
  .quem-somos-image {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 28px; /* Borda arredondada em pixels */
  }

}

/* Notebooks (1025px a 1440px) */
@media (min-width: 1025px) and (max-width: 1440px) {

  .quem-somos-container {
    padding: 0px 0px 0px 40px; 
    gap: 50px;
    margin-top: 0px;
  } 

  .quem-somos-title {
    font-size: 32px;
    margin-top: -120px;
  }


  .quem-somos-text {
  font-size: 20px;
  color: #D9D9D9;
  font-family: Montserrat;
  text-align: justify;
  font-weight: 400;
  text-align: left;
  max-width: 700px; /* Largura máxima em pixels */
  margin-bottom: 0px; /* Espaçamento inferior um pouco maior */
  
  
    
    
  }

  .mas-text {
    color: #D9D9D9;
    font-family: Montserrat;
    font-size: 20px; /* Tamanho da fonte em pixels */
    font-weight: 700;
    text-align: left;
    max-width: 650px; /* Largura máxima em pixels */
    margin-bottom: 0px; /* Espaçamento inferior um pouco maior */
  }
  
  .quem-somos-left,
  .quem-somos-right {
    padding: 30px; /* Ajuste de padding para notebooks */
  }

 

  .quem-somos-image-container {
    width: 449px;
    max-width: 449%;
    height: 573px;
    border-radius: 28px; /* Borda arredondada em pixels */
    overflow: hidden;
    background: #D9D9D9;
  }
  
  .quem-somos-image {
    width: 449px;
    height: 573px;
    display: block;
    border-radius: 28px; /* Borda arredondada em pixels */
  }

}

/* Desktops (1441px e acima) */
@media (min-width: 1441px) {
  .quem-somos-container {
    padding: 0px 0px 20px 70px; /* Notebook e PC */
    margin-top: 0px;
    gap: 200px;
  }
  .quem-somos-title {
    font-size: 32px;
    margin-top: -120px;
  }

  .quem-somos-text {
    font-size: 20px;
    max-width: 650px;
    line-height: 24.38px;
    font-weight: 400;
    text-align: justify;
    text-align: left;
    
    
  }

  .quem-somos-left {
    padding: 0px 0px 0px 0px; /* Padding maior para desktops */
  }
  
  
  .quem-somos-image {
    width: 449px;
    height: 573px;
    display: block;
    
    border-radius: 28px; /* Borda arredondada em pixels */
  }

  .quem-somos-image-container {
    width: 449px;
    height: 573px;
    max-width: 100%;
    
    
    
  }

  .mas-text {
    color: #D9D9D9;
    font-family: Montserrat;
    font-size: 20px; /* Tamanho da fonte em pixels */
    font-weight: 700;
    text-align: left;
    max-width: 650px; /* Largura máxima em pixels */
    
  }

}

/* Smartphones (até 767px) */
@media (max-width: 767px) {
  .quem-somos-container {
    flex-direction: column;
    align-items: center;
    padding: 10px; /* Ajuste de padding para smartphones */
  }

  .quem-somos-left,
  .quem-somos-right {
    width: 100%;
    padding: 10px; /* Ajuste de padding menor para smartphones */
  }

  .quem-somos-title {
    font-size: 25px;
    text-align: left;
    margin-top: -140px; /* Ajuste específico para smartphones */
  }

  .quem-somos-text {
    font-size: 18px;
    text-align: justify;

    max-width: 100%;
    
  }

  .mas-text {
    
    font-size: 18px; /* Tamanho da fonte em pixels */
    font-weight: 700;
    text-align: left;
    max-width: 650px; /* Largura máxima em pixels */
    
  }

  .somos-highlight,
  .quem-somos-text {
    text-align: left;
    max-width: 100%; /* Garantir texto centralizado em telas menores */
  }


  .quem-somos-image {
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 28px; /* Borda arredondada em pixels */
  }

  .quem-somos-image-container {
    max-width: 100%;
    height: auto;
    text-align: center;
   }
}