/* componentes/NotFound.css */
.not-found {
    text-align: center;
    color: #D9D9D9;
    font-size: 20px;
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
  }
  
  h1 {
    font-size: 30px;
    color: #AC7953;
    padding-top: 100px;
    padding-bottom: 20px;
  }
  
  .btn-home {
    display: inline-block;
    padding: 10px 15px;
    background-color: #AC7953;
    color: #D9D9D9;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    font-size: 15px;
    margin-bottom: 70px;
  }
  
  .btn-home:hover {
    background-color: #D9D9D9;
    color: #133227;
  }
  
  .team {
    color: #D9D9D9;
    font-size: 15px;
    padding-bottom: 20px;
  }
  