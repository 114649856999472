/* Estilos Gerais */
body {
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif; /* Adicione a fallback font */
}

.porque-container {
  width: 100%;
  height: 950px;
  flex-shrink: 0;
  background-color: #D9D9D9;
  margin-top: 0; /* Este pode ser removido, já que margin: 0 é padrão */
}

.espaco {
  height: 20px;
}

.tituloabc, 
.porqueinvestirem, 
.subtituloinvestir, 
.textoabc, 
.titulo-especial, 
.frase-final p, 
.textocorrido {
  color: #133227;
  font-family: 'Montserrat', sans-serif;
  line-height: normal;
}

.tituloabc {
  font-size: 12px;
  font-weight: 800;
  text-align: center;
  align-items: center;
}

.porqueinvestirem {
  text-align: center;
  font-size: 32px;
  font-weight: 300;
}

.subtituloinvestir {
  font-size: 32px;
  font-weight: 800;
}

.textoabc {
  font-size: 18px;
  font-weight: 400;
  width: auto;
  padding: 0 40px;
  text-align: center;
  align-items: center;
  hyphens: auto;
}

.conteiner-lado-a-lado {
  display: flex;
  justify-content: space-around;
  margin-top: 28px;
  align-items: center;
}

.lado-esquerdo,
.lado-direito {
  text-align: left;
  flex-direction: row;
  align-items: center;
}

.linha-svg {
  margin: 0;
}

.titulo-especial {
  font-size: 35px;
  font-weight: 700;
  text-align: center;
  line-height: 1;
  vertical-align: middle;
}

.lado-esquerdo img,
.lado-direito img {
  text-align: center;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  margin-left: 0;
  align-items: center;
  vertical-align: middle;
}

.frase-final {
  text-align: center;
  margin-top: 50px;
  align-items: center;
}

.frase-final p {
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  hyphens: auto;
}

.frase-final-proporcionando {
  font-weight: 700;
}

.textocorrido {
  font-size: 20px;
  hyphens: auto;
}

/* Estilos Responsivos */
@media (min-width: 1200px) {
  .porque-container {
    height: auto;
  }
  
  .tituloabc {
    font-size: 17px;
  }

  .porqueinvestirem,
  .subtituloinvestir {
    font-size: 37px;
  }

  .textoabc {
    font-size: 25px;
    padding: 0 200px 20px;
  }
  
  .frase-final p,
  .textocorrido {
    font-size: 25px;
  }
  
  .titulo-especial {
    font-size: 37px;
  }
}

@media only screen and (max-width: 768px) {
  .porque-container {
    padding: 0;
    margin: 0;
    margin-top: 0;
    width: 100%;
    height: auto;
  }

  .porqueinvestirem,
  .subtituloinvestir {
    font-size: 25px;

    
  }

  .espaco {
    height: 0;
  }

  .textoabc {
    margin: 0px 0px 0px 0px;
    font-size: 18px;
    text-align: left;
    overflow: hidden;
    padding: 20px;
  }

  .titulo-especial {
    margin-bottom: 20px;
    font-size: 20px;
    text-align: left;
    overflow: hidden;
  }

  .textocorrido {
    font-size: 18px;
    text-align: left;
    margin: 0 0px;
    overflow: hidden;
    margin-top: 10px;
    hyphens: auto;
  }

  .textocorrido p {
    margin-bottom: 5px;
  }

  .conteiner-lado-a-lado {
    flex-direction: column;
    margin: 20px;
  }

  .lado-esquerdo,
  .lado-direito {
    margin-bottom: 10px;
    text-align: left;
  }

  .lado-esquerdo {
    margin-bottom: 40px;
  }

  .lado-esquerdo img,
  .lado-direito img {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    
  }

  .frase-final {
    margin-top: 20px;
    padding: 0 0 20px;
    margin-left: 10px;
    margin-right: 10px;
   
  }

  .frase-final p {
    text-align: center;
    font-size: 18px;
    margin: 0 0px;
    overflow: hidden;

  }

 
}
