

  
/* Adiciona a regra para remover o destaque azul ao clicar em links */
a:focus {
  outline: none;
}
 html {
/* Adiciona a regra para evitar destaque azul ao tocar */
* {
  -webkit-tap-highlight-color: transparent;
}

/* Cor do fundo da barra de rolagem */
::-webkit-scrollbar {
  background-color: #133227;
}

/* Cor do "track" (área não preenchida pela alça) */
::-webkit-scrollbar-track {
  background-color: #133227;
}

/* Cor da alça da barra de rolagem */
::-webkit-scrollbar-thumb {
  background-color: #133227;
}

/* Estilo da borda da alça da barra de rolagem */
::-webkit-scrollbar-thumb:hover {
  background-color: #AC7953;
}
  
/* Cor do fundo da barra de rolagem no Firefox */
scrollbar-color: #D1CCBE #133227;
}



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
