

.termos-container {
    padding: 20px;
    padding-top: 15px;
    background-color: #2C4C3F;
    text-align: left;
    
  }
  
  .termos-container h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #D9D9D9;
    
  }
  
  .termos-container h2 {
    font-size: 20px;
    margin-top: 20px;
    color: #D9D9D9;
  }
  
  .termos-container p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
    color: #D9D9D9;
  }
  