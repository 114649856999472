.popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #133227;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 999;
    max-width: 300px;  /* Ajuste o tamanho máximo conforme necessário */
    width: 100%;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
  }
  
  .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Montserrat;
    font-size: 20px;
    color: #FFF;
    cursor: pointer;
  }
  
  .buttons-container {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    gap: 50px;
  }

  .botaoopcao {
    background-color: #133227;
    color: #FFF;
    font-family: Montserrat;
    font-size: 15px;
  }

  .whatsapp-opcao {
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    color: #AC7953; /* Cor verde do WhatsApp */
    vertical-align: middle;
    background: none;
    cursor: pointer;
    padding: 0;
  }
  
  .close-button {
    margin-top: 20px;
    background-color: #AC7953;
    color: #133227;
    font-family: Montserrat;
    font-size: 15px;
    cursor: pointer;
  }
  