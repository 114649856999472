.fachada {
    width: auto;
    height: 742px;    
    background: #2C4C3F url('fundo-otimizado.webp') 50% / cover no-repeat;
    border: 30px solid #2C4C3F; /* Adiciona uma borda de 30 pixels com a cor desejada */
    border-radius: 0px 0px 120px 120px;
    box-sizing: border-box; /* Garante que a borda esteja incluída na largura/altura total */
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -70px;
    left: auto;
    z-index: 0;
    
  }
  
  .fachada-content {
    max-width: 1244px; /* Use 'max-width' em vez de 'width' para garantir que não ultrapasse a largura desejada */
    width: 100%;
    margin: 0 auto; /* Centraliza o conteúdo horizontalmente */
    padding: 0; /* Adiciona algum espaço nas bordas se necessário */
    box-sizing: border-box; /* Garante que a largura inclua padding e bordas */
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }


  .text-left {
    margin-left: 50px;
    color: #133227;
    font-family: Montserrat;
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }

  
  .main-text {
    font-size: 32px;
    font-weight: 700;
    line-height: normal;
    text-align: left;
    cursor: pointer;
  }
  
  .sub-text {
    margin-top: 0px;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    text-align: left;
    cursor: pointer;
  }
  
  .cta-button {
    width: 152px;
    height: 48px;
    flex-shrink: 0;
    text-align: left;
    color: #133227;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 700;
    line-height: normal;
    cursor: pointer;
    background: none;
    outline: none;
    padding: 20px 0px 10px 0px; /* Ajuste conforme necessário */
    border: none;
    display: flex;
    align-items: center;
  }
  
  .icone-seta {
    margin-left: 10px; /* Espaçamento entre o texto e o ícone */
    width: 20px; /* Ajuste conforme necessário */
    height: 21px; /* Ajuste conforme necessário */
  }

  @media (min-width: 1200px) {

    .fachada {
      height: 792px;
    }
    .fachada-content {
      max-width: 1550px; 
    }
    .text-left {
      margin-left: 50px;
    }
    .main-text {
      font-size: 37px;
    }
    .sub-text {
      font-size: 25px;
    }
    .cta-button {
      width: 392px;
      font-size: 25px;
      padding: 20px 0px 10px 0px; /* Ajuste conforme necessário */
    }

    .icone-seta {
      margin-left: 30px; /* Espaçamento entre o texto e o ícone */
      width: 30px; /* Ajuste conforme necessário */
      height: 31px; /* Ajuste conforme necessário */
    }
    
   
    
  }


  /* Estilos para mobile (até 768px) */

@media (max-width: 768px) {

.fachada{
  height: 880px;
  width: auto;    
  background: #2C4C3F url('fundo-otimizado.webp') 50% / cover no-repeat;
  border: 5px solid #2C4C3F; /* Adiciona uma borda de 30 pixels com a cor desejada */
  border-radius: 0px 0px 100px 100px;
  box-sizing: border-box; /* Garante que a borda esteja incluída na largura/altura total */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -70px;
  left: auto;
  z-index: 0;
  
}

.fachada-content {
  width: 100%;
  margin: 0 auto; /* Centraliza o conteúdo horizontalmente */
  padding: 0; /* Adiciona algum espaço nas bordas se necessário */
  box-sizing: border-box; /* Garante que a largura inclua padding e bordas */
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.text-left {
  margin-left: 20px;
  color: #133227;
  font-family: Montserrat;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.main-text {
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  cursor: pointer;
  max-width: 160px; /* importante posicionamento do texto */
}

.sub-text {
  margin-top: 0px;
  font-size: 20px;
  max-width: 180px; /* importante posicionamento do texto */
  font-weight: 400;
  line-height: normal;
  text-align: left;
  cursor: pointer;
}

}